<div class="login-card">

  <div class="login-header inria-serif-regular">
    {{'shared.partner-booking-system' | translate}}
  </div>

  <br>
  <br>

  <button mat-flat-button class="login-btn inter-700" (click)="onSubmit()"> {{'auth.log-in' | translate}} </button>
  <!-- <p class="pass-recover"> {{'auth.forgot-your-password' | translate}}?</p> -->

</div>

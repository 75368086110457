import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../service/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss'
})
export class LoginComponent implements OnInit {

  get isUserLoggedIn() {
    return this.authService.isUserLoggenIn
  }

  constructor(
    private router: Router,
    private authService: AuthService
  ) {

    if (this.isUserLoggedIn)
      this.onSubmit();


  }

  ngOnInit() {

    if (this.isUserLoggedIn)
      this.onSubmit();

  }


  onSubmit() {
    this.router.navigate(['/booking']);
  }
}

import { DOCUMENT } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from './auth/service/auth.service';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {
  isIframe = false;

  get isUserLoggedin() {
    return this.authService.isUserLoggenIn;
  }

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private authService: AuthService,
    private translate: TranslateService,
    public dialog: MatDialog
  ) {

    const localStorage = document.defaultView?.localStorage;
    if (localStorage) {
      let userSavedLanguage = localStorage.getItem('language');
      if (userSavedLanguage)
        this.translate.use(userSavedLanguage);
    }
  }

  ngOnInit(): void {
    if (typeof window !== "undefined") {
      this.isIframe = window !== window.parent && !window.opener;
    }

    this.authService.initializeAuthentication();
    this.authService.subscribeAfterLogin();
  }
}

import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../../../auth/service/auth.service';
import { PartnerService } from '../../services/partner.service';
import { Result } from '../../models/result';
import { PartnerDetails } from '../../models/partner-details';
import { SnackbarService } from '../../services/snackbar.service';
import { HttpErrorResponse } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationComponent } from '../confirmation/confirmation.component';
import { UserDto } from '../../models/user.dto';


@Component({
  selector: 'app-grid',
  templateUrl: './grid.component.html',
  styleUrl: './grid.component.scss'
})
export class GridComponent implements OnInit {
  languages = [
    { code: 'en', label: 'English' },
    { code: 'sv', label: 'Svenska' }
  ];
  selectedLanguage: string = 'en';

  get isUserLoggedin() {
    return this.authService.isUserLoggenIn;
  }

  detailsLoaded = false;
  get partnerDetails(): PartnerDetails | null {
    let userId = this.authService.claims?.sub;
    let idp_access_token = this.authService.claims?.idp_access_token;

    if (this.isUserLoggedin && userId != null && !this.detailsLoaded) {

      if (idp_access_token) {
        const oid = this.getADUserIdFromToken(idp_access_token);
        this.getUserDetails(oid);
      }
      else {
        this.getPartnerDetails(userId);
      }

      this.detailsLoaded = true;
    }
    return this.partnerService.partnerDetails;
  }


  constructor(
    private authService: AuthService,
    private partnerService: PartnerService,
    private translate: TranslateService,
    private snackbarService: SnackbarService,
    private dialog: MatDialog
  ) {
    this.selectedLanguage = this.translate.currentLang;
  }


  ngOnInit(): void {
  }

  changeLanguage(lang: string) {
    this.selectedLanguage = lang;
    this.translate.use(lang);
    localStorage.setItem('language', lang);
  }



  getPartnerDetails(userId: string) {
    this.partnerService.getPartnerByUserId(userId).subscribe(
      {
        next: (result: Result<PartnerDetails> | null) => {
          if (result && result.isSuccess) return;
          else if (result)
            this.snackbarService.openSnackBar(result.errorMessage);
        },
        error: (err: HttpErrorResponse) => {
          this.showErrorMessage(err)
          // this.getUserDetails(userId);
        }
      }
    );
  }

  getUserDetails(userId: string) {
    this.partnerService.getUserById(userId).subscribe(
      {
        next: (result: Result<UserDto> | null) => {
          if (result && result.isSuccess) { }
          else {
            this.snackbarService.openSnackBar(this.translate.instant('dialogs.not-fonus-user-notification'));
            this.openErrorDialog();
          }
        },
        error: (err: HttpErrorResponse) => {
          this.snackbarService.openSnackBar(this.translate.instant('dialogs.not-fonus-user-notification'));
          this.openErrorDialog();
          this.showErrorMessage(err)
        }
      }
    );
  }

  getADUserIdFromToken(token: string): string {
    try {
      // Split the JWT by '.' and decode the payload (second part)
      const payload = token.split('.')[1];
      const decodedPayload = atob(payload);

      // Parse the JSON string to an object
      const decodedToken = JSON.parse(decodedPayload);

      // Return the oid if it exists, otherwise return null
      return decodedToken?.oid || '';
    } catch (error) {
      console.error('Failed to decode token:', error);
      return '';
    }
  }


  logout() {
    this.authService.logout();
  }

  // errors
  private showErrorMessage(error: HttpErrorResponse) {
    if (error?.status == 404) {
      // this.snackbarService.openSnackBar('No partner conntected to account. Logged as internal user', 'success');
      return;
    }

    if (!error.error.errorMessage) {
      this.snackbarService.openSnackBar(error.message);
    }
    else {
      this.snackbarService.openSnackBar(error.error.errorMessage);
    }

  }

  openErrorDialog(): void {
    const dialogRef = this.dialog.open(ConfirmationComponent, {
      data: 'error',
    });

    dialogRef.afterClosed().subscribe(result => {
      this.authService.logout();
    });
  }
}

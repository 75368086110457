import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'auth-login-failed',
  templateUrl: './login-failed.component.html',
  styleUrls: ['./login-failed.component.scss'],
})
export class LoginFailedComponent implements OnInit {

  constructor(private router: Router) {}

  ngOnInit(): void {
    // this.router.navigate(['/']);
  }

  reload(){
    window.location.pathname = "/";
  }
}

import { MatToolbarModule } from '@angular/material/toolbar';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { GridComponent } from './components/grid/grid.component';
import { AppRoutingModule } from '../app-routing.module';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { CalendarComponent } from './components/calendar/calendar.component';
import { MatDialogModule } from '@angular/material/dialog';
import { NgbDatepicker } from '@ng-bootstrap/ng-bootstrap';
import { MatChipsModule } from '@angular/material/chips';
import { FormsModule } from '@angular/forms';
import { ConfirmationComponent } from './components/confirmation/confirmation.component';
import { MatMenuModule } from '@angular/material/menu';
import { TranslateModule } from '@ngx-translate/core';
import { CapitalizeDirective } from './directives/tolowercase-capitalise.directive';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { EmailPreviewComponent } from './components/email-preview/email-preview.component';



@NgModule({
  declarations: [
    GridComponent,
    CalendarComponent,
    ConfirmationComponent,
    CapitalizeDirective,
    EmailPreviewComponent
  ],
  exports: [
    GridComponent,
    CalendarComponent
  ],
  imports: [
    CommonModule,
    MatToolbarModule,
    AppRoutingModule,
    MatIconModule,
    MatButtonModule,
    MatDialogModule,
    NgbDatepicker,
    NgbDatepicker,
    MatChipsModule,
    FormsModule,
    MatMenuModule,
    TranslateModule,
    MatProgressSpinnerModule
  ]
})
export class SharedModule { }

import { Directive, ElementRef, HostListener } from '@angular/core';

@Directive({
  selector: '[toLowerCapitalize]'
})
export class CapitalizeDirective {

  constructor(private el: ElementRef) { }

  @HostListener('input', ['$event']) onInputChange(event: any) {
    const initialValue = this.el.nativeElement.value;
    this.el.nativeElement.value = initialValue.toLowerCase().replace(/(^\w|\s\w)/g, (m: string) => m.toUpperCase());
    if (initialValue !== this.el.nativeElement.value) {
      event.stopPropagation();
    }
  }
}

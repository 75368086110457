import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginFailedComponent } from './auth/components/login-failed/login-failed.component';
import { MsalGuard } from '@azure/msal-angular';
import { LoginComponent } from './auth/components/login/login.component';

const routes: Routes = [
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'login-failed',
    component: LoginFailedComponent,
  },
  {
    path: 'booking',
    children: [
      { path: '', loadChildren: () => import('./booking/booking.module').then(m => m.BookingModule) },
    ],
    canActivate: [MsalGuard]
  },
  { path: '**', redirectTo: '/login', pathMatch: 'full' }
];

@NgModule({
  // imports: [RouterModule.forRoot(routes, {})],
  imports: [RouterModule.forRoot(routes, {
    initialNavigation:'enabledBlocking'
  })],
  exports: [RouterModule]
})
export class AppRoutingModule { }

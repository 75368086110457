  <mat-toolbar>

    <!-- logo -->
    <div class="logo" routerLink="/">
      <img class="logo-img" src="assets/images/logo_smaller.png">
    </div>

    <span class="spacer"></span>

    <ng-container *ngIf="isUserLoggedin">
      <div *ngIf="!partnerDetails?.imageUrl" class="header-title">
        {{ partnerDetails?.companyName ?? 'shared.internal-lawyer' | translate }}
      </div>

      <div *ngIf="partnerDetails?.imageUrl" class="partner-logo">
        <img class="logo-img" style="cursor: default;" [src]="partnerDetails?.imageUrl">
      </div>
    </ng-container>

  <!-- <div *ngIf="!isUserLoggedin"> -->
    <div>
    <mat-menu #languageMenu="matMenu">
      <ng-container *ngFor="let lang of languages">
        <button mat-menu-item class="lang-btn" (click)="changeLanguage(lang.code)">
        <span [ngStyle]="{'font-weight': selectedLanguage == lang.code ? 'bold' : '' }"> {{lang.label | translate}} </span>
        </button>
      </ng-container>
    </mat-menu>

        <button mat-button [matMenuTriggerFor]="languageMenu">
          <mat-icon>language</mat-icon> <mat-icon style="margin-left: -5px;">keyboard_arrow_down</mat-icon>
        </button>
      </div>

      <div *ngIf="isUserLoggedin" class="login-user-container">
        <button mat-button class="logout-btn" (click)="logout()">{{'auth.log-out' | translate}}</button>
      </div>



</mat-toolbar>

  <div class="content-area">
    <router-outlet></router-outlet> <!-- Routed components will be displayed here -->
  </div>


